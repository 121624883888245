<template>
  <teleport to="body">
    <Transition
      name="slide"
    >
      <div
        v-if="open"
        class="overflow-y-none fixed right-0 top-0 z-50 h-screen w-full space-y-8 bg-white shadow-3xl md:w-1/2"
        @click.stop
      >
        <div class="flex h-full flex-col">
          <div class="flex items-center justify-between bg-secondary-800 px-6 py-5 font-quicksand text-white">
            <h2 class="mb-0">
              {{ title }}
            </h2>

            <button
              class="btn group mx-3 h-min cursor-pointer border-white p-3 hover:bg-white hover:text-black"
              @click="close"
            >
              <UiIcon
                name="times"
                class="text-white transition duration-300 group-hover:text-black"
              />
            </button>
          </div>

          <div class="h-full w-full overflow-auto overscroll-contain p-6 text-black sm:p-10">
            <slot />
          </div>
        </div>
      </div>
    </Transition>
  </teleport>
</template>

<script setup lang="ts">
defineProps<{
  title: string,
  open: boolean,
}>();

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'update:open', value: boolean): void
}>();

function close() {
  emit('update:open', false);
  emit('close');
}

defineExpose({
  close,
});
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  @apply transition-all ease-in-out duration-500;
}

.slide-leave-to,
.slide-enter-from,
.slide-leave {
  @apply translate-x-full;
}

.slide-enter-to {
  @apply translate-x-0;
}
</style>
